import React, { useRef } from "react"
import { TextInput, TextArea, Form, Button } from "../common"
import "./contact.scss"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import emailjs from "emailjs-com"
import { mailService, mailTemplate, mailUser } from "../../utils/constants"

function Contact() {
  const honeyPot = useRef((Math.random() + 1).toString(36).substring(5))

  const schema = yup.object().shape({
    ["from_name" + honeyPot.current]: yup.string().required(),
    ["email" + honeyPot.current]: yup.string().email().required(),
    ["message" + honeyPot.current]: yup.string().max(500).required(),
  })
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const handleContact = (values, e) => {
    e.preventDefault()

    const mailInfo = {
      from: values["from_name" + honeyPot.current],
      subject: values["email" + honeyPot.current],
      text: values["message" + honeyPot.current],
    }

    emailjs
      .send(mailService, mailTemplate, mailInfo, mailUser)
      .then(
        () => {
          e.target.reset()
          reset()
          alert("Message sent. I will get back to you shortly.")
        },
        () => {
          alert("An error occurred. Please try again")
        }
      )
      .catch(() => {
        alert("An error occurred. Please try again later")
      })
  }

  return (
    <section id="contact">
      <div className="contact-title">
        <h2>CONTACT</h2>
        <h3>Get in touch for more info</h3>
      </div>
      <Form onSubmit={handleSubmit(handleContact)}>
        {/* Real fields */}
        <TextInput
          register={register}
          error={errors.from_name}
          required
          name={"from_name" + honeyPot.current}
          label="Your name"
        />
        <TextInput
          register={register}
          error={errors.email}
          name={"email" + honeyPot.current}
          required
          type="email"
          label="Your e-mail"
        />
        <TextArea
          register={register}
          required
          error={errors.message}
          name={"message" + honeyPot.current}
          label="Your message"
        />
        {/*  Honeypot fields */}
        <TextInput
          labelClass="ohnohoney"
          inputClass="ohnohoney"
          register={register}
          name="from_name"
          label="Your name"
        />
        <TextInput
          labelClass="ohnohoney"
          inputClass="ohnohoney"
          register={register}
          name="email"
          type="email"
          label="Your e-mail"
        />
        <TextArea
          labelClass="ohnohoney"
          inputClass="ohnohoney"
          register={register}
          name="message"
          label="Your message"
        />
        <Button
          type="submit"
          label={isSubmitting ? "Sending" : "Submit"}
          disabled={isSubmitting}
        />
      </Form>
    </section>
  )
}

export default Contact

import React, { useEffect } from "react"
import "./experience.scss"

const experiences = [
  {
    position: "Senior Software Engineer",
    company: "EPAM Systems",
    tasks: [
      "Led frontend architecture overhaul for legacy app, ensuring seamless tech stack transition.",
      "Guided junior React developers in frontend skills and promoted coding standards via pair programming and code reviews.",
      "Developed POCs shaping architectural decisions and driving project success.",
      "Developed, maintained, and upgraded numerous Spring Boot microservices, strengthening the backend ecosystem and ensuring high performance.",
      "Created communication pipelines for our microservices using Kafka, ensuring proper schema evolution and fault tolerance. Assisted in the migration of many of our applications to Azure cloud.",
    ],
    skills: ["React", "Spring Boot", "Kafka", "Azure", "PostgreSQL"],
    period: "09/2022 - Present",
  },
  {
    position: "Front End Developer",
    company: "Ixly B.V.",
    tasks: [
      "Collaborated with designers to ensure UI/UX feasibility, enhancing the aesthetics and usability of our React applications.",
      "Upgraded and maintained company Ruby on Rails applications, ensuring smooth functionality and user satisfaction.",
      "Boosted application performance by optimizing data-fetching logic and refining database queries, resulting in faster load times and improved efficiency.",
      "Oversaw the technical execution of minor and major releases, ensuring timely, efficient deployments while proactively managing potential production incidents.",
      "Integrated modern frontend testing libraries, significantly improving system reliability and ensuring robust code quality.",
    ],
    skills: ["Ruby on Rails", "React", "Amazon S3", "PostgreSQL"],
    period: "04/2020 - 06/2022",
  },
  {
    position: "Full Stack Developer - Research Associate (part-time)",
    company: "Intelligent Systems & Software Engineering Labgroup (ISSEL)",
    tasks: [
      "Developed, and enhanced React applications for the Sities Project platforms, delivering dynamic and user-friendly interfaces.",
      "Built and tested core backend microservices using ExpressJS and GraphQL.",
      "Optimized React performance with lazy loading and code splitting, improving speed.",
      "Managed MongoDB databases effectively, supporting robust data handling and retrieval.",
      "Facilitated the communication with Elsa home assistant using web sockets and RabbitMQ.",
    ],
    skills: ["React", "Express", "GraphQL", "Mongo", "Websockets", "Rabbitmq"],
    period: "06/2019 - 03/2020",
  },
  {
    position: "Full Stack Developer",
    company: "Versatile Innovation Labs (ViLabs)",
    tasks: [
      "Designed, developed, and tested RESTful APIs using ExpressJS, significantly improving application performance and reliability.",
      "Deployed containerized web app instances using Docker, optimizing scalability and streamlining operations.",
      "Implemented and maintained CI/CD pipelines, accelerating development cycles and improving deployment efficiency.",
      "Developed a hybrid application using React, React Native and Expo, broadening platform accessibility and reaching a wider audience.",
    ],
    skills: ["React", "Express", "Mongo", "React Native"],
    period: "09/2018 - 03/2020",
  },
]

function Experience() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.previousElementSibling.classList.toggle(
            "show",
            entry.isIntersecting
          )
          entry.target.classList.toggle("show", entry.isIntersecting)

          if (entry.isIntersecting) observer.unobserve(entry.target)
        })
      },
      {
        threshold: 0.3,
      }
    )

    const cards = document.querySelectorAll(".experience-card")
    cards.forEach((card) => observer.observe(card))
  }, [])

  return (
    <section className="experiences" id="experience">
      <h2 className="experiences-title">EXPERIENCE</h2>
      <div className="experiences-container">
        {experiences.map(({ position, company, tasks, period, skills }, eIndex) => (
          <React.Fragment key={eIndex}>
            <div className="experience-timeline" style={{ "--row": eIndex }}>
              <div className="experience-period">{period}</div>
              <div
                className={`experience-dot${
                  eIndex === experiences.length - 1 ? " present" : ""
                }`}
              />
              <div className="experience-line" />
            </div>
            <div
              className="experience-card"
              style={{ "--column": eIndex % 2 === 0 ? 1 : 3, "--row": eIndex }}
            >
              <h2 className="experience-position">{position}</h2>
              <h3 className="experience-company">{company}</h3>
              <ul className="experience-tasks">
                {tasks.map((task, tIndex) => (
                  <li key={tIndex}>{task}</li>
                ))}
              </ul>
              <ul className="experience-skills">
                {skills.map((skill, sIndex) => (
                  <li className="pill" key={sIndex}>
                    {skill}
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  )
}

export default Experience

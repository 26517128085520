import React from "react"
import "./about.scss"
import ProgrammerVideo from "../../assets/videos/programmer.webm"
import ProgrammerImage from "../../assets/images/programmer.png"

function About() {
  return (
    <section className="grey" id="about">
      <video
        poster={ProgrammerImage}
        className="video"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={ProgrammerVideo} />
      </video>
      <div className="about">
        <h2>ABOUT</h2>
        <p>
          Senior Software Engineer with 7 years of experience in enhancing frontend
          architecture and mentoring junior developers. Proficient in React.js, CSS,
          and Typescript, and skilled at driving project success through coding
          standards and technical leadership.
        </p>
        <div className="divider" />
        <p>
          Dedicated team player and quick learner, always ready to tackle challenging
          tasks head-on. Equipped with experience in backend development, including
          creating APIs and microservices in diverse programming languages, as well
          as working with cloud platforms, CI/CD pipelines, and message queues like
          Kafka.
        </p>
      </div>
    </section>
  )
}

export default About

import React from "react"
import PropTypes from "prop-types"

import Redux from "../../assets/images/redux.svg"
import Mongo from "../../assets/images/mongo.svg"
import ReactSvg from "../../assets/images/react.svg"
import Express from "../../assets/images/express.svg"
import Html from "../../assets/images/html.svg"
import Css from "../../assets/images/css.svg"
import Graphql from "../../assets/images/graphql.svg"
import Javascript from "../../assets/images/javascript.svg"
import Assessifai from "../../assets/images/assessifai.png"
import Node from "../../assets/images/node.svg"
import Npm from "../../assets/images/npm.svg"
import Expo from "../../assets/images/expo.svg"
import Openai from "../../assets/images/openai.svg"

const svgs = {
  redux: Redux,
  mongo: Mongo,
  react: ReactSvg,
  express: Express,
  html: Html,
  css: Css,
  graphql: Graphql,
  javascript: Javascript,
  assessifai: Assessifai,
  node: Node,
  npm: Npm,
  expo: Expo,
  openai: Openai,
}

function SVG({ name, className }) {
  return <img className={className} alt={`${name} svg`} src={svgs[name]} />
}

SVG.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

SVG.defaultProps = {
  className: "",
}

export default SVG
